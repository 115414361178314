<template>
  <div id="app">
    <van-tabbar route v-if="$route.meta.showTab">
      <van-tabbar-item replace to="/dongtai">
        <template #icon="props">
          <img :src="props.active ? icon1.active : icon1.inactive" />
        </template>
        动态
      </van-tabbar-item>
      <van-tabbar-item replace to="/xiaoxi">
        <template #icon="props">
           <img :src="props.active ? icon3.active : icon3.inactive" />
        </template>
        消息
      </van-tabbar-item>
      <van-tabbar-item replace to="/crm">
        <template #icon="props">
          <img src="./static/CRM/tongji.png" />
        </template>
        CRM
      </van-tabbar-item>
      <van-tabbar-item replace to="/bangong">
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
        办公
      </van-tabbar-item>
      <van-tabbar-item replace to="/user" icon="friends-o">
        <template #icon="props">
          <img  :src="props.active ? icon2.active : icon2.inactive" />
        </template>
        我的
      </van-tabbar-item>
    </van-tabbar>
    <router-view />
  </div>
</template>
<script type="text/javascript">
    // 定义一些公共的属性和方法
    const token = ''
    const name = ''
    const session_id = ''
    function commonFun() {
        console.log("公共方法")
    }
    // 暴露出这些属性和方法
    export default {
        token,
        name,
        session_id,
        commonFun
    }
</script>

<script>
import bangong from "./static/CRM/bangong.png";
import bangong1 from "./static/办公/bangong.png";

import dongtai from "./static/办公/dongtai.png";
import dongtai1 from "./static/CRM/dongtai.png";

import my from "./static/我的/wode.png";
import my1 from "./static/CRM/wode.png";

import xiaoxi from "./static/消息/tuceng1.png";
import xiaoxi1 from "./static/CRM/xiaoxi.png";

export default {
	
	     created () {
	        //在页面加载时读取localStorage里的状态信息
	        if (localStorage.getItem("data") ) {
	            //replaceState替换数据 Object.assign合并对象
	            this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(localStorage.getItem("data"))))
	        };
	        //在页面刷新时将vuex里的信息保存到localStorage里
	        window.addEventListener("beforeunload",()=>{
	            localStorage.setItem("data",JSON.stringify(this.$store.state))
	        });
	      },
  data() {
    return {
      active: 2,
      icon: {
        active: bangong1,
        inactive: bangong,
      },
      icon1: {
        active: dongtai,
        inactive: dongtai1,
      },
      icon2: {
        active: my,
        inactive: my1,
      },
       icon3: {
        active: xiaoxi,
        inactive: xiaoxi1,
      },
    };
  },
};
</script>
<style lang="scss">
</style>
