import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/crm',
    name: 'CRM',
    component: () => import('../views/CRM.vue'),
     meta: {
      showTab: true //如果需要显示就加上这个
    }
  },
  {
    path: '/xierizhi',
    name: 'xierizhi',
    component: () => import('../views/xierizhi.vue')
  },
  {
    path: '/xiaoxi',
    name: 'xiaoxi',
    component: () => import('../views/xiaoxi.vue'),
     meta: {
      showTab: true //如果需要显示就加上这个
    }
  },
  {
    path: '/',
    name: 'shouye',
    component: () => import('../views/shouye.vue')
  },
  {
    path: '/dong1',
    name: 'dong1',
    component: () => import('../views/dong1.vue')
  },
  {
    path: '/qita',
    name: 'qita',
    component: () => import('../views/qita.vue'),
	meta: {
	  showTab: true //如果需要显示就加上这个
	}
  },
  {
    path: '/dong2',
    name: 'dong2',
    component: () => import('../views/dong2.vue')
  },
  {
    path: '/wanshanziliao',
    name: '完善资料',
    component: () => import('../views/wanshanziliao.vue')
  },
  {
    path: '/kehu',
    name: 'kehu',
    component: () => import('../views/kehu.vue')
  },
  {
    path: '/kehuxiangqing',
    name: 'kehuxiangqing',
    component: () => import('../views/kehuxiangqing.vue')
  },
  {
    path: '/user',
    name: 'kehuxiangqing',
    component: () => import('../views/wode.vue'),
      meta: {
      showTab: true //如果需要显示就加上这个
    }
  },
  {
    path: '/bangong',
    name: 'bangong',
    component: () => import('../views/bangong.vue'),
     meta: {
      showTab: true //如果需要显示就加上这个
    }
  },{
    path: '/bohao',
    name: 'bohao',
    component: () => import('../views/bohao.vue'),
    meta: {
      showTab: true //如果需要显示就加上这个
    }
  },
  {
    path: '/dongtai',
    name: 'dongtai',
    component: () => import('../views/dongtai.vue'),
     meta: {
      showTab: true //如果需要显示就加上这个
    }
  },
]

const router = new VueRouter({
	mode:'hash',
  routes
})

export default router