import Vue from 'vue'
import VueRouter from 'vue-router';
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import axios from 'axios'
import VueAxios from 'vue-axios'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';

axios.defaults.baseURL="https://dianhu.szyyt.cc/"
axios.defaults.headers = {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'};

import 'vant/lib/index.css';
import './assets/reset.css';

Vue.use(VueRouter);
Vue.use(ViewUI);
Vue.use(Vant);

Vue.use(VueAxios,axios);
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
